<script>
import ViewAllTemplate from './ViewAllTemplate.vue'
import CandidatureListItem from '@/components/general/CandidatureListItem.vue'
import ModalMessages from '@/components/general/ModalMessages'
export default {
  name: 'UnderAnalysisCandidatures',
  components: { ViewAllTemplate, CandidatureListItem, ModalMessages },
  data () {
    return {
      company: { },
      position: { },
      showModal: false,
      underAnalysisCandidatures: [],
      notFinishedCandidatures: [],
      isLoading: true
    }
  },
  created () {
    if (this.$route.query.help === 1) {
      this.$store.commit('setInviteDetails', { showMessageInvite: true, title: this.$t('applications.intro:title'), text: this.$t('applications.intro:description') })
      this.$router.replace({ name: 'individual.candidature.under.analysis' })
    }
    this.$store.dispatch('attemptGetUserMatches', { aggregator: this.getCompanyDomainAndWorkspace() }).then((data) => {
      const allPosition = []
      for (const item of data) {
        allPosition.push({
          position: {
            allowNewApplications: item.position.allowNewApplications,
            company: {
              eeocEnabled: item.position.company.eeocEnabled,
              image: item.position.company.image,
              logo: item.position.company.logo,
              name: item.position.company.name,
              ofccpDisability: item.position.company.ofccpDisability,
              ofccpVeteran: item.position.company.ofccpVeteran,
              subdomain: item.position.company.subdomain
            },
            disabledOnly: item.position.disabledOnly,
            finishedAt: item.position.finishedAt,
            id: item.position.id,
            ignoreSteps: {
              faq: item.position.ignoreSteps.faq,
              interview: item.position.ignoreSteps.interview,
              isPublicAnnouncement: item.position.ignoreSteps.isPublicAnnouncement,
              questionnaires: item.position.ignoreSteps.questionnaires,
              videos: {
                company: item.position.ignoreSteps.videos.company,
                leadership: item.position.ignoreSteps.videos.leadership,
                position: item.position.ignoreSteps.videos.position
              }
            },
            interested: item.position.interested,
            meet: item.position.meet,
            meeting: item.position.meeting,
            messagesCounter: item.position.messagesCounter,
            positionUrl: item.position.positionUrl,
            status: item.position.status,
            title: item.position.title
          }
        })
      }
      this.underAnalysisCandidatures = allPosition.filter(candidature => candidature.position.status === 'no_filter')
      this.$emit('positions', allPosition)
      this.isLoading = false
    })
  },
  methods: {
    handleShowModalMessages (item) {
      this.company = { name: item.position.company.name, image: item.position.company.logo }
      this.position = { name: item.position.title, id: item.position.id }
      this.showModal = !this.showModal
    },
    refreshMessages () {
      this.$store.dispatch('attemptGetUserMatches')
        .then((data) => {
          const allPosition = []
          for (const item of data) {
            allPosition.push({
              position: {
                company: {
                  logo: item.position.company.logo,
                  name: item.position.company.name
                },
                id: item.position.id,
                messagesCounter: item.position.messagesCounter,
                positionUrl: item.position.positionUrl,
                status: item.position.status,
                title: item.position.title
              }
            })
          }
          this.underAnalysisCandidatures = allPosition.filter(candidature => candidature.position.status === 'no_filter')
          this.$emit('positions', allPosition)
        })
    }
  }
}
</script>
<template>
  <div>
  <view-all-template v-if="!isLoading"
    description="candidature.list:under.analysis.subtitle"
    :items="underAnalysisCandidatures"
  >
    <template v-slot:item="slotProps">
      <candidature-list-item :candidature="slotProps.item" @handleModalMessageCandidature="handleShowModalMessages(slotProps.item)"></candidature-list-item>
    </template>
  </view-all-template>
  <modal-messages
      v-if="showModal"
      :company=company
      :position=position
      @close="showModal = false"
      @refreshMessagesCandidature="refreshMessages()"
    ></modal-messages>
  </div>
</template>
<style>

</style>
